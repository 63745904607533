import Overview from "./components/Overview/Overview";
import background from "./assets/background.jpg"
import useImageLoaded from "./hooks/useImageLoaded"
import Loader from "./components/Loader/Loader";

function App() {
    const [ref, loaded, onLoad] = useImageLoaded()

    const path = window.location.pathname
    if (path.startsWith('/compilers-video')) {
      window.location.href = 'https://www.youtube.com/watch?v=b6xPtHvd6T8'

      return <></>
    }

    return (
            <div>
                <img src={ background } className="w-screen h-screen absolute left-0 top-0" ref={ ref }
                     onLoad={ onLoad } alt="Background"/>
                <div className="w-screen h-screen flex justify-start items-center text-white relative p-5 md:p-16 bg-gradient-to-t from-black to-transparent">
                    <Overview/>
                </div>
                { loaded || <Loader/> }
            </div>
    );
}

export default App;
