const Loader = () => {
    return (
        <div>
            <div id="loader" className="absolute top-0 left-0 bg-black w-screen h-screen">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
