import React from 'react';
import twitter from '../../../assets/icons/twitter.png'
import github from '../../../assets/icons/github.png'
import linkedin from '../../../assets/icons/linkedin.png'

const Icons = () => {
    return (
        <div className="flex gap-4 items-center">
            <a href="https://linkedin.com/in/florrdv">
                <img src={linkedin} alt="LinkedIn" />
            </a>
            <a href="https://github.com/florrdv">
                <img src={github} alt="Github" />
            </a>
            <a href="https://twitter.com/florrdv">
                <img src={twitter}  alt="Twitter" />
            </a>
        </div>
    );
};

export default Icons;
