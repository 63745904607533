import React from 'react';
import Icons from "./Icons/Icons";
import useGithubStatus from "../../hooks/useGithubStatus";

const Overview = () => {
    const githubUsername = "florrdv"
    const { status } = useGithubStatus(githubUsername)
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
                <span className="text-5xl">Flor Ronsmans De Vry</span>
                <span className="text-2xl text-gray-800">SOFTWARE ENGINEER</span>
            </div>
                {(status?.emoji || status?.message) && <span className="text-base text-gray-400">{ status?.emoji }{ status?.message } - {githubUsername} on github</span>}
            <Icons />
        </div>
    );
};

export default Overview;
