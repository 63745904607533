import fetch from "../lib/fetch"
import useSWR from "swr";

function parseStatus(raw) {
    const domparser = new DOMParser()
    const doc = domparser.parseFromString(raw, "text/html")

    return {
        message: doc.querySelector(".user-status-message-wrapper")?.innerText?.trim(),
        emoji: doc.querySelector(".user-status-emoji-container")?.innerText?.trim()
    }

}

function useGithubStatus(username) {
    const { data, error } = useSWR(`https://divine-smoke-8a14.florrdv.workers.dev/?https://github.com/${username}`, fetch, { refreshInterval: 30_000 })

    return {
        status: parseStatus(data),
        error
    }
}

export default useGithubStatus